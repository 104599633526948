import dayjs from "dayjs";
import backupAPI from "./api-backup";

export const getStationData = async (station_id) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/data/rest.php/stations?filter=ST_ID,eq,${station_id}&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            data = data?.stations;
            const formatted = {};
            data?.columns?.forEach((item, index) => {
                formatted[item] = data.records[0][index];
            })
            formatted.last_update = dayjs().format("DD.MM.YYYY HH:mm");
            return formatted;
        }).catch((err) => {
            return backupAPI.getStationData(station_id);
        })
}

export const getNow = async (station_id) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/data/rest.php/Now?filter=Station,eq,${station_id}&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            data = data?.Now;
            const formatted = {};
            if (data?.records?.length > 0) {
                data?.columns?.forEach((item, index) => {
                    formatted[item] = data.records[0][index];
                })
            }
            formatted.last_update = dayjs().format("DD.MM.YYYY HH:mm");
            return formatted;
        }).catch((err) => {
            return backupAPI.getNow(station_id);
        })
}

export const getAllData = async (station_id) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/data/rest.php/view?columns=SEND_TIME,WIND_SP,WIND_GUST,WIND_MAX,WIND_ANG,WIND_DIR,ST_ID,TEMP,ST_ID,CL_BASE,D_POINT,PRESSURE,MOIST&filter=ST_ID,EQ,${station_id}&order=TS,desc&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            data = data.view;

            const formatted = [];
            if (data?.records?.length > 0) {
                data?.records?.forEach((item) => {
                    let formattedData = {};
                    item.forEach((v, i) => {
                        formattedData[data?.columns[i]] = v
                        return formattedData;
                    })
                    formatted.push(formattedData);
                })
            }
            return formatted;
        }).catch((err) => {
            return backupAPI.getAllData(station_id);
        })
}

export const getAvgWindDirectionData = async (station_id, interval = 15) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/averageDirection.php?station=${station_id}&interval=${interval}&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            return data;
        }).catch((err) => {
            return backupAPI.getAvgWindDirectionData(station_id);
        })
}

export const getInterval = async (station_id, interval = 15) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/data/rest.php/Interval${interval}?filter=Station,eq,${station_id}&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            data = data['Interval' + interval];
            const formatted = {};
            if (data?.records?.length > 0) {
                data?.columns?.forEach((item, index) => {
                    formatted[item] = data.records[0][index];
                })
            }
            return formatted;
        }).catch((err) => {
            return backupAPI.getInterval(station_id);
        })
}

export const getTrend = async (station_id) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?http://flumen.club/wp/zbelacRain.php?station=${station_id}&t=${Date.now()}`)
        .then(response => response.json())
        .then(data => {
            return data[0] || {};
        }).catch((err) => {
            return backupAPI.getTrend(station_id);
        })
}

export const getSkewT = async (lat, lng, date, time, model) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?https://apig2.skysight.io/model`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Origin': 'https://skysight.io',
            'Referer': 'https://skysight.io',
            'x-cors-headers': JSON.stringify({
                'Content-Type': 'application/json',
                'Origin': 'https://skysight.io',
                'Referer': 'https://skysight.io'
            }),
        },
        body: JSON.stringify({
            "req": "skewt",
            "lat": parseFloat(lat),
            "lon": parseFloat(lng),
            "date": dayjs(date).format('YYYYMMDD'),
            "hour": dayjs.utc(time).format('HHmm'),
            "region": model,
        })
    })
        .then(response => response.json())
        .then(data => {
            return data;
        }).catch((err) => {
            return backupAPI.getSkewT(lat, lng, date, time, model);
        })
}


export const getPointForecast = async (lat, lng, times, model) => {
    return await fetch(`https://cloudflare-cors-anywhere.vssmeteo.workers.dev/?https://apig2.skysight.io/model`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Origin': 'https://skysight.io',
            'Referer': 'https://skysight.io',
            'x-cors-headers': JSON.stringify({
                'Content-Type': 'application/json',
                'Origin': 'https://skysight.io',
                'Referer': 'https://skysight.io'
            }),
        },
        body: JSON.stringify({
            "req": "table",
            "lat": parseFloat(lat),
            "lon": parseFloat(lng),
            "times": times,
            "region": model,
        })
    })
        .then(response => response.json())
        .then(data => {
            return data;
        }).catch((err) => {
            return backupAPI.getPointForecast(lat, lng, times, model);
        })
}
